<template>
    <v-container class="account-locking">
        <h4>Account Locking</h4>
        <extended-form
            :submit="submit"
            :form-data="form.data"
            :form-ready="form.ready"
            :disabled="!form.ready || !hasAccessRole"
        >
            <v-row>
                <v-col md="8" lg="6">
                    <v-switch
                        v-model="form.data.enabled"
                        label="Account Locking Enabled"
                        :rules="form.rules.enabled"
                    />
                    <text-field
                        class="account-locking__max-attempts"
                        type="number"
                        :form="form"
                        field-key="maxAttempts"
                        dense
                        label="Maximum Attempts Before Locking"
                    />
                    <v-switch
                        v-model="form.data.unlockOnPasswordReset"
                        label="Allow Password Reset to Unlock Account"
                        :rules="form.rules.unlockOnPasswordReset"
                    />
                </v-col>
            </v-row>
        </extended-form>
    </v-container>
</template>

<script>
import ExtendedForm from '@/components/form/ExtendedForm'
import TextField from '@/components/form/TextField'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, formSnackbar } from '@/helpers/snackbar'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'

export default {
    name: 'AccountLockingForm',
    components: { TextField, ExtendedForm },
    data: () => ({
        form: {
            data: {
                enabled: null,
                maxAttempts: null,
                unlockOnPasswordReset: null,
            },
            errors: {},
            rules: {
                enabled: [],
                unlockOnPasswordReset: [],
                maxAttempts: [(v) => (v && v >= 10) || 'Max attempts must be greater than 10'],
            },
            ready: false,
        },
        authId: null,
        tab: null,
    }),
    computed: {
        ...mapGetters({
            clientId: 'getIlluminateClientId',
            getAuthByField: 'authentication/getAuthByField',
            hasRole: 'auth/hasRole',
        }),
        passwordAuth: {
            get() {
                return this.getAuthByField('strategy', 'password')
            },
            cache: false,
        },
        hasAccessRole() {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT)
        },
    },
    watch: {
        passwordAuth() {
            this.setForm()
        },
    },
    mounted() {
        this.setForm()
    },
    methods: {
        setForm: function () {
            if (this.passwordAuth) {
                const accountLockout = this.passwordAuth?.options?.accountLockout
                this.authId = this.passwordAuth.id
                this.form.data = Object.assign(this.form.data, accountLockout)
                this.form.ready = !!this.passwordAuth
            }
        },
        submit: async function () {
            const data = { authConfig: { options: { accountLockout: this.form.data } } }

            const result = await this.patch({ id: this.authId, clientId: this.clientId, data })

            if (result.data.error) {
                await errorSnackbar({ subtext: 'Error saving password settings' })
            } else {
                await formSnackbar({
                    isEdit: true,
                    type: 'Authentication type',
                    identifier: 'Password Settings',
                })
                await this.getAuthentication({ clientId: this.clientId })
            }
        },
        ...mapActions({
            patch: 'authentication/patch',
            getAuthentication: 'authentication/get',
        }),
    },
}
</script>

<style>
.account-locking__max-attempts .v-input__control {
    max-width: 95px;
}
</style>
